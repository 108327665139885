<template>
  <div class="product-container">
    <div class="scroll-container">
      <div class="bg-wrapper">
        <div class="content-wrapper">
          <!-- 注册 -->
          <div class="item-register-wrap">
            <h1 class="item-title">登录成功</h1>
            <p class="item-tips-tag">请选择你感兴趣的标签</p>
            <div class="item-login-content">
              <ul class="item-tab-list">
                <li :class="{'active': curIndex == 0}">关注领域</li>
                <li :class="{'active': curIndex == 1}">关注产品</li>
                <li :class="{'active': curIndex == 2}">兴趣话题</li>
              </ul>

              <ul v-if="curIndex=== 0" class="item-tag-list-wrap">
                <li :class="{'active': item.checked == true}" v-for="(item,index,key) in realmList" :key="key" @click="realmTagHandle(index,item)">{{ item.name }}</li>
              </ul>
              <ul v-if="curIndex=== 1" class="item-tag-list-wrap">
                <li :class="{'active': item.checked == true}" v-for="(item,index,key) in productList" :key="key" @click="productHandle(index,item)">{{ item.name }}</li>
              </ul>
              <ul v-if="curIndex=== 2" class="item-tag-list-wrap">
                <li :class="{'active': item.checked == true}" v-for="(item,index,key) in topicList" :key="key" @click="topicTagHandle(index,item)">{{ item.name }}</li>
              </ul>
            </div>

            <div class="btn-wrap">
              <p class="sure" @click="nextStep">确定</p>
              <p v-if="curIndex > 0" class="cancle" @click="prevStep">返回上一步</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import indexApi from '@/api/index'
export default {
  data() {
    return {
      type: 'register',
      form: {

      },

      list: [
        '新冠肺炎',
        '甲状腺疾病',
        '糖尿病',
        'nCoV',
        '消化系统疾病',
        '新冠肺炎',
        '甲状腺疾病',
        '新冠肺炎',
        '甲状腺疾病',
        
      ],

      curIndex: 0,
      curIndex1: 0,
      curIndex2: 0,
      curIndex3: 0,


      disabled: false,

      options: [{
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则',
            children: [{
              value: 'yizhi',
              label: '一致'
            }, {
              value: 'fankui',
              label: '反馈'
            }, {
              value: 'xiaolv',
              label: '效率'
            }, {
              value: 'kekong',
              label: '可控'
            }]
          }]
        }],

        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          code: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          
        },

        realmList: [],
        productList: [],
        topicList: [],

        productIdList: [],
        realmIdList: [],
        topicIdList: []
      
    }
  },
  created() {
    this.getSelectTagList();
  },
  mounted() {
    
  },
  methods: {
    handleChange() {},

    submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      // 去登陆
      goLogin() {
        this.$router.push({name: 'login'})
      },

      // 发送验证码事件
      sendCode() {
        // console.log(this.sendCodeHandle(this.sendCodeHandle))
        this.sendCodeHandle()
      },

      // 发送验证码逻辑
      sendCodeHandle() {
        let secondCount = 3, timer = null;
        timer = setInterval(()=>{
          if(secondCount > 0){
            this.disabled = true;
            this.codeBtnText = secondCount + 's后再发送';
            secondCount -- ;
          } else {
            clearInterval(timer);
            this.codeBtnText = '发送验证码'
            secondCount = 60;
            this.disabled = false;
          }
          
        },1000)
      },

      // 获取产品列表
    getSelectTagList() {
      let params = {
          
      };
      indexApi.getSelectTagList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data;
          this.realmList = data && data.realmList;
          this.productList =  data && data.productList;
          this.topicList =  data && data.topicList;

          this.realmList.map((item)=>{
            item.checked = false;
          })

          this.productList.map((item)=>{
            item.checked = false;
          })

          this.topicList.map((item)=>{
            item.checked = false;
          })
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

      // 领域-标签点击
      realmTagHandle(index,item) {
        this.$set(this.realmList[index],'checked',!item.checked);
        this.$forceUpdate()

        this.realmIdList = this.realmList.filter((item)=>{
          return item.checked == true;
        })

        
      },

      // 产品-标签点击
      productHandle(index,item) {
        this.$set(this.productList[index],'checked',!item.checked);
        this.$forceUpdate();
        

        this.productIdList = this.productList.filter((item)=>{
          return item.checked == true;
        })

        
      },

      // 话题-标签点击
      topicTagHandle(index,item) {
        this.$set(this.topicList[index],'checked',!item.checked);
        this.$forceUpdate();

        this.topicIdList = this.topicList.filter((item)=>{
          return item.checked == true;
        })

      },

      // 确定下一步
      nextStep() {
        if(this.curIndex == 0 && this.realmIdList.length === 0){
          this.$message.error('请选择领域')
          return;
        }else{
          this.saveTag();
        }
        
      },

      // 保存标签
      saveTag(){

        let productIdList = [];
        this.productIdList.map((item)=>{
          productIdList.push(item.id)
        })

        let realmIdList = [];
        this.realmIdList.map((item)=>{
          realmIdList.push(item.id)
        })

        let topicIdList = [];
        this.topicIdList.map((item)=>{
          topicIdList.push(item.id)
        })
        let params = {
          productIdList: productIdList,
          realmIdList: realmIdList,
          topicIdList: topicIdList
        };
      indexApi.saveTag(params).then((res)=>{
        if(res && res.code === 1000){
          if(this.curIndex == 2){
            this.curIndex = 2
            this.$router.push({path: '/home'})
          } else{
            this.curIndex+=1
          }
        }
      },(err)=>{
        this.$message.success(err && err.message || '保存失败，请联系管理员！')
      })
      },

      // 上一步
      prevStep() {
        this.curIndex-=1

        //this.$router.push({path: '/home'})
        
      },
  }
}
</script>

<style scoped lang="scss">
.product-container {
  background-image: url("~./images/bg.png");
  background-size: cover;
  line-height: 1;
  position: relative;

.bg-wrapper {
  // display: flex;
  // align-items: flex-start;
  background: url("~./images/bg-login.png") center center /100% 100%;
  width: 10.6rem;
  min-height: 7.4rem;
  margin: .6rem auto 0;
  // padding-top: .6rem;
  
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  // padding-left: .3rem;
  // padding-top: .4rem;
  // padding-bottom: .4rem;

}

.content-wrapper {
  width: 100%;
  .item-register-wrap{
    padding-bottom: 1rem;
    overflow: hidden;
  }
  .item-login-wrap,.item-register-wrap {
    width: 100%;
    .item-title {
      text-align: center;
      font-size: .40rem;
      font-family: Noto Sans CJK SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: .48rem;
      text-align: center;
      margin-left: -.06rem;
      margin-top: .61rem;
    }
    .item-tips-tag{
      text-align: center;
      font-size: .20rem;
      font-family: Noto Sans CJK SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .38rem;
    }
    .item-login-content {
      width: 4.2rem;
      margin: 1.06rem auto 0 !important;
      text-align: center;
      .item-tab-list{
        // overflow: hidden;
        width: 7.8rem;
        margin: auto;
        display: flex;
        background: #F5F5F7;
        border-radius: .2rem;
        li{
          float: left;
          flex: 1;
          justify-content: center;
          padding: .12rem 0;
          font-size: .16rem;
          font-family: Noto Sans CJK SC;
          font-weight: 400;
          color: #999999;
          &.active{
            font-size: .16rem;
            font-family: Noto Sans CJK SC;
            font-weight: 500;
            color: #333333;
            background: #ffffff;
            border-radius: .2rem;
            box-shadow: -1px 0px 17px #ccc;
          }
        }
      }
      .item-tag-list-wrap{
        margin: .4rem auto 0;
        width: 7.8rem;
        text-align: center;
        li{
          padding: 0 .44rem 0 .44rem;
          display: inline-block;
          border: 1px solid #DDDDDD;
          border-radius: .26rem;
          font-size: .18rem;
          font-family: Noto Sans CJK SC;
          font-weight: 400;
          color: #333333;
          height: 0.52rem;
          line-height: 0.52rem;
          margin: 0 .2rem .15rem 0;
          cursor: pointer;
          &.active{
            border: 1px solid #503291;
          }
          &.active::before{
              content: '';
              width: .18rem;
              height: .18rem;
              background: url('./images/click.png') no-repeat center /100% 100%;
              display: inline-block;
              margin-right: .1rem;
            }
        }
      }
    }
    .btn-wrap{
      margin-top: .3rem;
      text-align: center;
      .sure{
        width: 2rem;
        height: .6rem;
        background: url('./images/ok-bg.png') no-repeat center /100% 100%;
        margin: 0 auto;
        line-height: .6rem;
        text-align: center;
        font-size: .18rem;
        font-family: Noto Sans CJK SC;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }
      .cancle{
        font-size: .14rem;
        font-family: Noto Sans CJK SC;
        font-weight: 400;
        color: #999999;
        margin-top: .2rem;
        cursor: pointer;
      }
    }
  }

  .item-register-wrap{
    .item-send-code,.item-login{
      margin-top: 0 !important;
    }
    .item-login-content{
      width: auto !important;
      margin: 1.6rem 1rem 0;
    }
    .item-left-wrap {
      width: 4rem;
      float: left;
    }

    .item-right-wrap {
      width: 4rem;
      float: right;
    }
    .el-input {
      height: 0.52rem;
    }
    .el-input__inner{
      font-size: .16rem !important;
      background: #F5F5F7;
      font-family: Noto Sans CJK SC;
      font-weight: 400;
      color: #999999;
      height: 0.52rem;
      
    }
    
  }
}
}

</style>
